import {order_path, cards_orders_path, root_path} from "../routes";
import {showFlashMessage} from "../src/flash";
var $middleNameChek = $('[name="user[profile_attributes][has_middle_name]"]');
import 'bootstrap/js/dist/modal'
import {gotoUrl} from "../src/common_methods";

$middleNameChek.on('click', function() {
    $('[name="user[profile_attributes][middle_name]"]').prop('disabled', !this.checked);
});
new CdekCalculator($('#cdek-calc'))

var $signInTab = $('a[href="#sign-in-tab"]')
var $signUpTab = $('a[href="#sign-up-tab"]')

// HACK: tab events not fired
$signUpTab.on('click', (e) => {
    $('#sign-in-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
})

$signInTab.on('click', (e) => {
    $('#sign-up-tab form').get(0).dispatchEvent(new Event('custom:clearFormErrors'));
})

$(document).on('click', '.decline-details', function(e) {
    $('#decline-details-header').text(e.currentTarget.dataset.header)
    $('#decline-details-body').html(e.currentTarget.dataset.text)
    $('#decline-details-modal').modal('show');
})
