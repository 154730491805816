import { urlFor, pathFor } from 'webpacker-routes'
const parent_spec = null
const default_url_options = {"host":"cbd.lognetix.net"}
export const agreement_profile_spec = ["/profile/agreement(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const agreement_profile_url = (...args) => urlFor(agreement_profile_spec, ...args)
export const agreement_profile_path = (...args) => pathFor(agreement_profile_spec, ...args)
export const airport_spec = ["/airports/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const airport_url = (...args) => urlFor(airport_spec, ...args)
export const airport_path = (...args) => pathFor(airport_spec, ...args)
export const airports_spec = ["/airports(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const airports_url = (...args) => urlFor(airports_spec, ...args)
export const airports_path = (...args) => pathFor(airports_spec, ...args)
export const ban_user_spec = ["/users/:id/ban(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const ban_user_url = (...args) => urlFor(ban_user_spec, ...args)
export const ban_user_path = (...args) => pathFor(ban_user_spec, ...args)
export const binding_code_order_spec = ["/orders/:id/binding_code(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const binding_code_order_url = (...args) => urlFor(binding_code_order_spec, ...args)
export const binding_code_order_path = (...args) => pathFor(binding_code_order_spec, ...args)
export const cancel_user_registration_spec = ["/cancel(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cancel_user_registration_url = (...args) => urlFor(cancel_user_registration_spec, ...args)
export const cancel_user_registration_path = (...args) => pathFor(cancel_user_registration_spec, ...args)
export const cards_orders_spec = ["/orders/cards(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const cards_orders_url = (...args) => urlFor(cards_orders_spec, ...args)
export const cards_orders_path = (...args) => pathFor(cards_orders_spec, ...args)
export const chat_spec = ["/chats/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const chat_url = (...args) => urlFor(chat_spec, ...args)
export const chat_path = (...args) => pathFor(chat_spec, ...args)
export const chat_messages_spec = ["/chats/:chat_id/messages(.:format)", ["chat_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const chat_messages_url = (...args) => urlFor(chat_messages_spec, ...args)
export const chat_messages_path = (...args) => pathFor(chat_messages_spec, ...args)
export const chats_spec = ["/chats(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const chats_url = (...args) => urlFor(chats_spec, ...args)
export const chats_path = (...args) => pathFor(chats_spec, ...args)
export const companies_spec = ["/companies(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const companies_url = (...args) => urlFor(companies_spec, ...args)
export const companies_path = (...args) => pathFor(companies_spec, ...args)
export const company_spec = ["/companies/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const company_url = (...args) => urlFor(company_spec, ...args)
export const company_path = (...args) => pathFor(company_spec, ...args)
export const confirmation_note_spec = ["/confirmation_note(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const confirmation_note_url = (...args) => urlFor(confirmation_note_spec, ...args)
export const confirmation_note_path = (...args) => pathFor(confirmation_note_spec, ...args)
export const create_chat_order_spec = ["/orders/:id/create_chat(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const create_chat_order_url = (...args) => urlFor(create_chat_order_spec, ...args)
export const create_chat_order_path = (...args) => pathFor(create_chat_order_spec, ...args)
export const customer_applications_spec = ["/customer_applications(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const customer_applications_url = (...args) => urlFor(customer_applications_spec, ...args)
export const customer_applications_path = (...args) => pathFor(customer_applications_spec, ...args)
export const decline_agreement_spec = ["/decline_agreement(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const decline_agreement_url = (...args) => urlFor(decline_agreement_spec, ...args)
export const decline_agreement_path = (...args) => pathFor(decline_agreement_spec, ...args)
export const destroy_user_session_spec = ["/logout(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const destroy_user_session_url = (...args) => urlFor(destroy_user_session_spec, ...args)
export const destroy_user_session_path = (...args) => pathFor(destroy_user_session_spec, ...args)
export const edit_airport_spec = ["/airports/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_airport_url = (...args) => urlFor(edit_airport_spec, ...args)
export const edit_airport_path = (...args) => pathFor(edit_airport_spec, ...args)
export const edit_company_spec = ["/companies/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_company_url = (...args) => urlFor(edit_company_spec, ...args)
export const edit_company_path = (...args) => pathFor(edit_company_spec, ...args)
export const edit_item_spec = ["/items/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_item_url = (...args) => urlFor(edit_item_spec, ...args)
export const edit_item_path = (...args) => pathFor(edit_item_spec, ...args)
export const edit_message_spec = ["/messages/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_message_url = (...args) => urlFor(edit_message_spec, ...args)
export const edit_message_path = (...args) => pathFor(edit_message_spec, ...args)
export const edit_profile_spec = ["/profile/edit(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_profile_url = (...args) => urlFor(edit_profile_spec, ...args)
export const edit_profile_path = (...args) => pathFor(edit_profile_spec, ...args)
export const edit_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_rails_conductor_inbound_email_url = (...args) => urlFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_rails_conductor_inbound_email_path = (...args) => pathFor(edit_rails_conductor_inbound_email_spec, ...args)
export const edit_task_spec = ["/tasks/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_task_url = (...args) => urlFor(edit_task_spec, ...args)
export const edit_task_path = (...args) => pathFor(edit_task_spec, ...args)
export const edit_user_spec = ["/users/:id/edit(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_url = (...args) => urlFor(edit_user_spec, ...args)
export const edit_user_path = (...args) => pathFor(edit_user_spec, ...args)
export const edit_user_password_spec = ["/password/edit(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_password_url = (...args) => urlFor(edit_user_password_spec, ...args)
export const edit_user_password_path = (...args) => pathFor(edit_user_password_spec, ...args)
export const edit_user_registration_spec = ["/settings(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const edit_user_registration_url = (...args) => urlFor(edit_user_registration_spec, ...args)
export const edit_user_registration_path = (...args) => pathFor(edit_user_registration_spec, ...args)
export const flow_element_spec = ["/flow_elements/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_element_url = (...args) => urlFor(flow_element_spec, ...args)
export const flow_element_path = (...args) => pathFor(flow_element_spec, ...args)
export const flow_element_tasks_spec = ["/flow_elements/:flow_element_id/tasks(.:format)", ["flow_element_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_element_tasks_url = (...args) => urlFor(flow_element_tasks_spec, ...args)
export const flow_element_tasks_path = (...args) => pathFor(flow_element_tasks_spec, ...args)
export const flow_elements_spec = ["/flow_elements(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const flow_elements_url = (...args) => urlFor(flow_elements_spec, ...args)
export const flow_elements_path = (...args) => pathFor(flow_elements_spec, ...args)
export const generate_binding_code_order_spec = ["/orders/:id/generate_binding_code(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const generate_binding_code_order_url = (...args) => urlFor(generate_binding_code_order_spec, ...args)
export const generate_binding_code_order_path = (...args) => pathFor(generate_binding_code_order_spec, ...args)
export const inspect_airports_spec = ["/airports/inspect(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const inspect_airports_url = (...args) => urlFor(inspect_airports_spec, ...args)
export const inspect_airports_path = (...args) => pathFor(inspect_airports_spec, ...args)
export const inspect_orders_spec = ["/orders/inspect(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const inspect_orders_url = (...args) => urlFor(inspect_orders_spec, ...args)
export const inspect_orders_path = (...args) => pathFor(inspect_orders_spec, ...args)
export const internet_magazin_spec = ["/internet-magazin(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const internet_magazin_url = (...args) => urlFor(internet_magazin_spec, ...args)
export const internet_magazin_path = (...args) => pathFor(internet_magazin_spec, ...args)
export const item_spec = ["/items/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const item_url = (...args) => urlFor(item_spec, ...args)
export const item_path = (...args) => pathFor(item_spec, ...args)
export const message_spec = ["/messages/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const message_url = (...args) => urlFor(message_spec, ...args)
export const message_path = (...args) => pathFor(message_spec, ...args)
export const new_airport_spec = ["/airports/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_airport_url = (...args) => urlFor(new_airport_spec, ...args)
export const new_airport_path = (...args) => pathFor(new_airport_spec, ...args)
export const new_chat_message_spec = ["/chats/:chat_id/messages/new(.:format)", ["chat_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const new_chat_message_url = (...args) => urlFor(new_chat_message_spec, ...args)
export const new_chat_message_path = (...args) => pathFor(new_chat_message_spec, ...args)
export const new_company_spec = ["/companies/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_company_url = (...args) => urlFor(new_company_spec, ...args)
export const new_company_path = (...args) => pathFor(new_company_spec, ...args)
export const new_order_item_spec = ["/orders/:order_id/items/new(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const new_order_item_url = (...args) => urlFor(new_order_item_spec, ...args)
export const new_order_item_path = (...args) => pathFor(new_order_item_spec, ...args)
export const new_rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_url = (...args) => urlFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_path = (...args) => pathFor(new_rails_conductor_inbound_email_spec, ...args)
export const new_rails_conductor_inbound_email_source_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_rails_conductor_inbound_email_source_url = (...args) => urlFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const new_rails_conductor_inbound_email_source_path = (...args) => pathFor(new_rails_conductor_inbound_email_source_spec, ...args)
export const new_user_spec = ["/users/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_url = (...args) => urlFor(new_user_spec, ...args)
export const new_user_path = (...args) => pathFor(new_user_spec, ...args)
export const new_user_confirmation_spec = ["/confirmation/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_confirmation_url = (...args) => urlFor(new_user_confirmation_spec, ...args)
export const new_user_confirmation_path = (...args) => pathFor(new_user_confirmation_spec, ...args)
export const new_user_password_spec = ["/password/new(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_password_url = (...args) => urlFor(new_user_password_spec, ...args)
export const new_user_password_path = (...args) => pathFor(new_user_password_spec, ...args)
export const new_user_registration_spec = ["/register(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_registration_url = (...args) => urlFor(new_user_registration_spec, ...args)
export const new_user_registration_path = (...args) => pathFor(new_user_registration_spec, ...args)
export const new_user_session_spec = ["/login(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const new_user_session_url = (...args) => urlFor(new_user_session_spec, ...args)
export const new_user_session_path = (...args) => pathFor(new_user_session_spec, ...args)
export const order_spec = ["/orders/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_url = (...args) => urlFor(order_spec, ...args)
export const order_path = (...args) => pathFor(order_spec, ...args)
export const order_item_spec = ["/orders/:order_id/item(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_item_url = (...args) => urlFor(order_item_spec, ...args)
export const order_item_path = (...args) => pathFor(order_item_spec, ...args)
export const order_items_spec = ["/orders/:order_id/items(.:format)", ["order_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const order_items_url = (...args) => urlFor(order_items_spec, ...args)
export const order_items_path = (...args) => pathFor(order_items_spec, ...args)
export const orders_spec = ["/orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const orders_url = (...args) => urlFor(orders_spec, ...args)
export const orders_path = (...args) => pathFor(orders_spec, ...args)
export const profile_spec = ["/profile(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const profile_url = (...args) => urlFor(profile_spec, ...args)
export const profile_path = (...args) => pathFor(profile_spec, ...args)
export const progress_order_spec = ["/orders/:id/progress(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const progress_order_url = (...args) => urlFor(progress_order_spec, ...args)
export const progress_order_path = (...args) => pathFor(progress_order_spec, ...args)
export const rails_blob_representation_spec = ["/rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_url = (...args) => urlFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_path = (...args) => pathFor(rails_blob_representation_spec, ...args)
export const rails_blob_representation_proxy_spec = ["/rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)", ["signed_blob_id","variation_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_blob_representation_proxy_url = (...args) => urlFor(rails_blob_representation_proxy_spec, ...args)
export const rails_blob_representation_proxy_path = (...args) => pathFor(rails_blob_representation_proxy_spec, ...args)
export const rails_conductor_inbound_email_spec = ["/rails/conductor/action_mailbox/inbound_emails/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_url = (...args) => urlFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_path = (...args) => pathFor(rails_conductor_inbound_email_spec, ...args)
export const rails_conductor_inbound_email_reroute_spec = ["/rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)", ["inbound_email_id","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_reroute_url = (...args) => urlFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_reroute_path = (...args) => pathFor(rails_conductor_inbound_email_reroute_spec, ...args)
export const rails_conductor_inbound_email_sources_spec = ["/rails/conductor/action_mailbox/inbound_emails/sources(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_email_sources_url = (...args) => urlFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_email_sources_path = (...args) => pathFor(rails_conductor_inbound_email_sources_spec, ...args)
export const rails_conductor_inbound_emails_spec = ["/rails/conductor/action_mailbox/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_conductor_inbound_emails_url = (...args) => urlFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_conductor_inbound_emails_path = (...args) => pathFor(rails_conductor_inbound_emails_spec, ...args)
export const rails_direct_uploads_spec = ["/rails/active_storage/direct_uploads(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_direct_uploads_url = (...args) => urlFor(rails_direct_uploads_spec, ...args)
export const rails_direct_uploads_path = (...args) => pathFor(rails_direct_uploads_spec, ...args)
export const rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_key/*filename(.:format)", ["encoded_key","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_disk_service_url = (...args) => urlFor(rails_disk_service_spec, ...args)
export const rails_disk_service_path = (...args) => pathFor(rails_disk_service_spec, ...args)
export const rails_mailgun_inbound_emails_spec = ["/rails/action_mailbox/mailgun/inbound_emails/mime(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mailgun_inbound_emails_url = (...args) => urlFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mailgun_inbound_emails_path = (...args) => pathFor(rails_mailgun_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_emails_url = (...args) => urlFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_emails_path = (...args) => pathFor(rails_mandrill_inbound_emails_spec, ...args)
export const rails_mandrill_inbound_health_check_spec = ["/rails/action_mailbox/mandrill/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_mandrill_inbound_health_check_url = (...args) => urlFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_mandrill_inbound_health_check_path = (...args) => pathFor(rails_mandrill_inbound_health_check_spec, ...args)
export const rails_postmark_inbound_emails_spec = ["/rails/action_mailbox/postmark/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_postmark_inbound_emails_url = (...args) => urlFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_postmark_inbound_emails_path = (...args) => pathFor(rails_postmark_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_spec = ["/rails/action_mailbox/relay/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_relay_inbound_emails_url = (...args) => urlFor(rails_relay_inbound_emails_spec, ...args)
export const rails_relay_inbound_emails_path = (...args) => pathFor(rails_relay_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_spec = ["/rails/action_mailbox/sendgrid/inbound_emails(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_sendgrid_inbound_emails_url = (...args) => urlFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_sendgrid_inbound_emails_path = (...args) => pathFor(rails_sendgrid_inbound_emails_spec, ...args)
export const rails_service_blob_spec = ["/rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_url = (...args) => urlFor(rails_service_blob_spec, ...args)
export const rails_service_blob_path = (...args) => pathFor(rails_service_blob_spec, ...args)
export const rails_service_blob_proxy_spec = ["/rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)", ["signed_id","filename","format"], { ...default_url_options, ...{} }, parent_spec]
export const rails_service_blob_proxy_url = (...args) => urlFor(rails_service_blob_proxy_spec, ...args)
export const rails_service_blob_proxy_path = (...args) => pathFor(rails_service_blob_proxy_spec, ...args)
export const root_spec = ["/", [], { ...default_url_options, ...{} }, parent_spec]
export const root_url = (...args) => urlFor(root_spec, ...args)
export const root_path = (...args) => pathFor(root_spec, ...args)
export const success_order_spec = ["/orders/:id/success(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const success_order_url = (...args) => urlFor(success_order_spec, ...args)
export const success_order_path = (...args) => pathFor(success_order_spec, ...args)
export const sync_with_delivery_order_spec = ["/orders/:id/sync_with_delivery(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const sync_with_delivery_order_url = (...args) => urlFor(sync_with_delivery_order_spec, ...args)
export const sync_with_delivery_order_path = (...args) => pathFor(sync_with_delivery_order_spec, ...args)
export const task_spec = ["/tasks/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const task_url = (...args) => urlFor(task_spec, ...args)
export const task_path = (...args) => pathFor(task_spec, ...args)
export const track_orders_spec = ["/orders/track(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const track_orders_url = (...args) => urlFor(track_orders_spec, ...args)
export const track_orders_path = (...args) => pathFor(track_orders_spec, ...args)
export const unban_user_spec = ["/users/:id/unban(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const unban_user_url = (...args) => urlFor(unban_user_spec, ...args)
export const unban_user_path = (...args) => pathFor(unban_user_spec, ...args)
export const unfinished_orders_spec = ["/unfinished_orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const unfinished_orders_url = (...args) => urlFor(unfinished_orders_spec, ...args)
export const unfinished_orders_path = (...args) => pathFor(unfinished_orders_spec, ...args)
export const unread_chats_spec = ["/chats/unread(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const unread_chats_url = (...args) => urlFor(unread_chats_spec, ...args)
export const unread_chats_path = (...args) => pathFor(unread_chats_spec, ...args)
export const update_rails_disk_service_spec = ["/rails/active_storage/disk/:encoded_token(.:format)", ["encoded_token","format"], { ...default_url_options, ...{} }, parent_spec]
export const update_rails_disk_service_url = (...args) => urlFor(update_rails_disk_service_spec, ...args)
export const update_rails_disk_service_path = (...args) => pathFor(update_rails_disk_service_spec, ...args)
export const update_webpush_params_spec = ["/update_webpush_params(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const update_webpush_params_url = (...args) => urlFor(update_webpush_params_spec, ...args)
export const update_webpush_params_path = (...args) => pathFor(update_webpush_params_spec, ...args)
export const user_spec = ["/users/:id(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const user_url = (...args) => urlFor(user_spec, ...args)
export const user_path = (...args) => pathFor(user_spec, ...args)
export const user_confirmation_spec = ["/confirmation(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_confirmation_url = (...args) => urlFor(user_confirmation_spec, ...args)
export const user_confirmation_path = (...args) => pathFor(user_confirmation_spec, ...args)
export const user_orders_spec = ["/user_orders(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_orders_url = (...args) => urlFor(user_orders_spec, ...args)
export const user_orders_path = (...args) => pathFor(user_orders_spec, ...args)
export const user_password_spec = ["/password(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_password_url = (...args) => urlFor(user_password_spec, ...args)
export const user_password_path = (...args) => pathFor(user_password_spec, ...args)
export const user_registration_spec = ["/", [], { ...default_url_options, ...{} }, parent_spec]
export const user_registration_url = (...args) => urlFor(user_registration_spec, ...args)
export const user_registration_path = (...args) => pathFor(user_registration_spec, ...args)
export const user_session_spec = ["/login(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const user_session_url = (...args) => urlFor(user_session_spec, ...args)
export const user_session_path = (...args) => pathFor(user_session_spec, ...args)
export const users_spec = ["/users(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const users_url = (...args) => urlFor(users_spec, ...args)
export const users_path = (...args) => pathFor(users_spec, ...args)
export const was_active_users_spec = ["/users/was_active(.:format)", ["format"], { ...default_url_options, ...{} }, parent_spec]
export const was_active_users_url = (...args) => urlFor(was_active_users_spec, ...args)
export const was_active_users_path = (...args) => pathFor(was_active_users_spec, ...args)
export const yandex_request_retry_order_spec = ["/orders/:id/yandex_request_retry(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const yandex_request_retry_order_url = (...args) => urlFor(yandex_request_retry_order_spec, ...args)
export const yandex_request_retry_order_path = (...args) => pathFor(yandex_request_retry_order_spec, ...args)
export const yandex_request_update_state_order_spec = ["/orders/:id/yandex_request_update_state(.:format)", ["id","format"], { ...default_url_options, ...{} }, parent_spec]
export const yandex_request_update_state_order_url = (...args) => urlFor(yandex_request_update_state_order_spec, ...args)
export const yandex_request_update_state_order_path = (...args) => pathFor(yandex_request_update_state_order_spec, ...args)
